import React, { FC, useEffect } from "react";
// import company from "../../../../assets/company.svg";

// import logoNvidia from "../../../../assets/nvidia-logo.png";
import borcella from "../../../../assets/borcella.svg";
import { useOperatorProfileStore } from "../../../../stores/useOperatorProfile";
import CallerInfo from "../CallerInfo/CallerInfo";
import styles from "./mainScreen.module.scss";

export const MainScreen: FC = () => {
  const getOperator = useOperatorProfileStore((state) => state.getProfile);
  const operatorName = useOperatorProfileStore((state) => state.operatorProfile.name);
  // const operatorCompany = useOperatorProfileStore((state) => state.operatorProfile.org_name);

  useEffect(() => {
    if (!operatorName) {
      getOperator();
    }
  }, []);

  return (
    <>
      <CallerInfo animated isFinished={false} />
      <div className={styles.mainWrapper}>
        <div className={styles.mainInfo}>
          <img className={styles.logo} src={borcella} alt="logo"/>
          <h2 className={styles.logoTitle}>Market-Leading Biometric Authentication System</h2>
          {/*<span className={styles.titleUser}>Hello {operatorName}</span>*/}
          <div className={styles.companyWrapper}>
            {/*<img className={styles.company} src={company} />*/}
            {/*<span className={styles.titleCompany}>{operatorCompany}</span>*/}
            {/*<span className={styles.titleCompany}>By Borcella</span>*/}
          </div>
          <div className={styles.title}>{`Ready For The Next Call`}</div>
        </div>
      </div>
    </>
  );
};
