import React, { useEffect } from 'react';
import styles from './customerInfo.module.scss';
// import voiceBlack from '../../../../assets/voice-black.svg';
// import notVoiceGray from '../../../../assets/voice-not.svg';
// import { createdAt } from '../../../../utils/helpers/createdAt';
import { useCallInfoStore } from '../../../../stores/useCallInfo';
import { emitCommand, EmitCommandParams } from '../../../../utils/helpers/emitCommand';
import { TaskType } from '../../../../utils/enum/taskType';
import { useConnectionStore } from '../../../../stores/useConnection';
import { useNavigate } from 'react-router-dom';
import { useVadInfoStore } from '../../../../stores/useVadllInfo';
import { useEnrollInfoStore } from '../../../../stores/useEnrollInfo';
import { useVerifyInfoStore } from '../../../../stores/useVerifyInfo';
import { TaskStatuses } from '../../../../utils/enum/taskStatuses';

const SCREEN_CHANGE_DELAY = 4000;

export const CustomerInfo = () => {
  const navigate = useNavigate();
  const socket = useConnectionStore((state) => state.socket);

  const callId = useCallInfoStore((state) => state.call_id);
  const clientId = useCallInfoStore((state) => state.call_data.client.client_id);
  const refreshVadTotalSeconds = useVadInfoStore((state) => state.refreshVadTotalSeconds);

  const clientName = useCallInfoStore((state) => state.call_data.client.name);
  const callerId = useCallInfoStore((state) => state.call_data.client.claim_id);
  const voiceSignature = useCallInfoStore((state) => state.call_data.client.dt_signature);
  const enrollStatus = useEnrollInfoStore((state) => state.task_status);
  const verifyStatus = useVerifyInfoStore((state) => state.task_status);

  const needAutoVerification = voiceSignature && verifyStatus !== TaskStatuses.FINISHED;
  const needAutoEnroll = !voiceSignature && enrollStatus !== TaskStatuses.FINISHED;

  const startVerify = () => {
    const params: EmitCommandParams = {
      socket,
      task_type: TaskType.VERIFY,
      call_id: callId,
      client_id: clientId,
    };

    refreshVadTotalSeconds();
    emitCommand(params);

    navigate('/verify');
  };

  useEffect(() => {
    if (needAutoVerification) {
      setTimeout(() => {
        startVerify();
      }, SCREEN_CHANGE_DELAY);
    }

    if (needAutoEnroll) {
      setTimeout(() => {
        navigate('/enroll');
      }, SCREEN_CHANGE_DELAY);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.subWrapper}>
        <span className={styles.label}>Customer ID</span>
        <span className={styles.id}>{callerId || 'Unknown'}</span>
      </div>
      {clientName && <div className={styles.subWrapper}>
        <span className={styles.label}>Name</span>
        <span className={styles.name}>{clientName}</span>
      </div>}
      {/*<div className={styles.signatureWrapper}>*/}
      {/*  <img src={voiceSignature ? voiceBlack : notVoiceGray} alt="voice"></img>*/}
      {/*  <div className={styles.signatureData}>*/}
      {/*    {voiceSignature*/}
      {/*      ? `Voice signature created on: ${createdAt(voiceSignature)}`*/}
      {/*      : 'Voice signature not created'}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};
