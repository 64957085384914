import React, { FC, useState, useRef } from "react";
import partOne from "../../assets/BackgroundParts/partOne.svg";
import partFour from "../../assets/BackgroundParts/partFour.svg";
import partTwo from "../../assets/BackgroundParts/partTwo.svg";
import partThree from "../../assets/BackgroundParts/partThree.svg";

import rightOne from "../../assets/BackgroundParts/rightOne.svg";
import rightTwo from "../../assets/BackgroundParts/rightTwo.svg";
import rightThree from "../../assets/BackgroundParts/rightThree.svg";

import menu from "../../assets/menu.svg";
import microphone from "../../assets/microphone.svg";
import { useOnClickOutside } from "../../utils/hooks/useClickOutside";
import { MyModal } from "../Modal/Modal";
import styles from "./layout.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { WindowFocusHandler } from "../../utils/hooks/useWindowFocus";
import { useOperatorProfileStore } from "../../stores/useOperatorProfile";
// import { BottomInfo } from '../../pages/Home/components/BottomInfo/BottomInfo';

type LayoutProps = {
  children?: React.ReactNode;
};

export const Layout: FC<LayoutProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const refreshProfile = useOperatorProfileStore((state) => state.refreshProfile);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const logOut = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    refreshProfile();
    navigate("/login");
  };

  const isAuth = localStorage.getItem("token") || sessionStorage.getItem("token");
  const micURL = "https://mic." + window.location.hostname;

  return (
    <div className={styles.container}>
      {pathname === "/" &&
				<>
					<img src={partOne} style={{ position: "absolute", bottom: 0, left: 0 }}/>
					<img src={partTwo} style={{ position: "absolute", bottom: 0, left: 40 }}/>
					<img src={partThree} style={{ position: "absolute", bottom: 0, left: 106 }}/>
					<img src={partFour} style={{ position: "absolute", bottom: 0, left: 73 }}/>

					<img src={rightOne} style={{ position: "absolute", top: 100, right: 0 }}/>
					<img src={rightTwo} style={{ position: "absolute", top: 168, right: 0 }}/>
					<img src={rightThree} style={{ position: "absolute", top: 135, right: 0 }}/>
				</>}

      {pathname === "/login" && <header>
				<div className={`${styles.contentWrapper} ${styles.headerContent}`}>
					<div className={styles.infoWrapper}>
						<Link to={micURL} target="_blank" rel="noopener noreferrer">
							<img className={styles.microphone} src={microphone} alt="microphone"/>
						</Link>
					</div>
          {isAuth && (
            <div
              ref={dropdownRef}
              onClick={() => setIsOpen(!isOpen)}
              className={styles.menuWrapper}
            >
              <img className={styles.menu} src={menu} alt="menu"/>
              {isOpen && (
                <div onClick={logOut} className={styles.btnLog}>
                  Logout
                </div>
              )}
            </div>
          )}
				</div>
			</header>}
      <section className={styles.main} style={{ paddingTop: pathname !== "/login" ? "0" : "45px" }}>
        <div className={`${styles.contentWrapper} ${styles.mainContent}`}>{children}</div>
      </section>
      {/*<div className={styles.footer} />*/}
      <WindowFocusHandler/>
      {/*{pathname !== '/verify' && <BottomInfo />}*/}
      {pathname !== "/login" && <MyModal/>}
    </div>
  );
};
