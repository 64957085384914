import React, { useEffect, FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useCallInfoStore } from '../../stores/useCallInfo';
import { useConnectionStore } from '../../stores/useConnection';
import { useConnectionStatusStore } from '../../stores/useConnectionStatus';
import { CallStatus } from '../../utils/enum/callStatuses';
import { emitStatus } from '../../utils/helpers/emitCommand';
import { handleEventMessage } from '../../utils/helpers/handleEventMessage';
import { CustomerInfo } from './components/CustomerInfo/CustomerInfo';
import { MainScreen } from './components/MainScreen/MainScreen';
import { OngoingScreen } from './components/OngoingScreen/OngoingScreen';
import { useEnrollInfoStore } from '../../stores/useEnrollInfo';
import { useOperationInfoStore } from '../../stores/useOperationInfo';

export const Home: FC = () => {
  const socket = useConnectionStore((state) => state.socket);
  const setConnectionLost = useConnectionStatusStore((state) => state.setStatus);

  const callStatus = useCallInfoStore((state) => state.call_status);
  const callerPhoneNumber = useCallInfoStore((state) => state.call_data.from);
  const updateCallInfo = useCallInfoStore((state) => state.updateCallInfo);
  const refreshEnrollInfo = useEnrollInfoStore((state) => state.refreshEnrollInfo);
  const refreshOperationInfo = useOperationInfoStore((state) => state.refreshOperationInfo);

  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  useEffect(() => {
    localStorage.removeItem('action');
    if (!socket) return;

    refreshEnrollInfo();
    refreshOperationInfo();

    socket.on('connect', () => {
      setConnectionLost(false)
      emitStatus(socket, 'free');
    });
    socket.on('event', (msg) => {
      handleEventMessage(socket, msg, { updateCallInfo });
    });
    socket.on('disconnect', (msg) => {
      setConnectionLost(true);
      console.log(msg, "msg")
    })
    socket.on('connect_error', (msg) => {
      setConnectionLost(true);
      console.log(msg, "msg")
    })

    return () => {
      socket.off('event');
    };
  }, []);

  return (
    <>
      {!token && <Navigate to={'/login'} replace={true} />}
      {callStatus === CallStatus.STARTED || callStatus === CallStatus.PROGRESS ? (
        <>
          <OngoingScreen phoneNumber={callerPhoneNumber} />
          <CustomerInfo />
        </>
      ) : (
        <MainScreen />
      )}
    </>
  );
};
