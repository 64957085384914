// import errorIcon from '../../assets/not_varifayed.svg';
// import fraudDetectedIcon from '../../assets/fraud.svg';
// import successIcon from '../../assets/varifayed.svg';
// import enrollFailed from '../../assets/enroll_failed.svg';
// import enrollDone from '../../assets/enroll_done.svg';

import successIconNew from '../../assets/successIconNew.svg'
import warningNew from '../../assets/warningNew.svg';
import errorNew from '../../assets/errorNew.svg';

import { ActionStatuses } from '../enum/actionStatuses';

export const statusesMap = new Map([
  [
    ActionStatuses.NOT_VERIFIED,
    {
      text: 'Not verified',
      icon: warningNew,
      isSuccess: false,
      btnText: 'Try again',
      type: 'hide',
      color: '#EBB11D',
    },
  ],
  [
    ActionStatuses.FRAUD_DETECTED,
    {
      text: 'Fraud',
      icon: errorNew,
      isSuccess: false,
      btnText: 'Try again',
      type: 'hide',
      color: '#EA424A',
    },
  ],
  [
    ActionStatuses.VERIFIED,
    {
      text: 'Verified Human',
      icon: successIconNew,
      isSuccess: true,
      btnText: 'OK',
      type: 'hide',
      color: '#6BC495',
    },
  ],
  [
    ActionStatuses.TIMEOUT_EXCEEDED,
    {
      text: `The operation is not finished. Please, try again.`,
      icon: warningNew,
      isSuccess: false,
      btnText: 'Try again',
      type: 'hide',
      color: '#EBB11D',
    },
  ],
  [
    ActionStatuses.ENROLL_NOT_PASSED,
    {
      text: 'Voice signature creation failed',
      icon: warningNew,
      isEnroll: true,
      isSuccess: false,
      btnText: 'Try again',
      type: 'show',
      color: '#EBB11D',
    },
  ],
  [
    ActionStatuses.ENROLL_PASSED,
    {
      text: `Voice signature created successfully`,
      icon: successIconNew,
      isEnroll: true,
      isSuccess: true,
      btnText: 'OK',
      type: 'show',
      color: '#6BC495',
    },
  ],
  [
    ActionStatuses.ERROR,
    {
      text: 'Something went wrong!',
      subText: 'Please try again',
      icon: errorNew,
      color: '#EA424A',
    },
  ],
]);
