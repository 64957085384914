import React, { FC } from 'react';
import microMute from '../../../../assets/microMute.svg';
import endCall from '../../../../assets/endCall.svg';
import callerPhoto from '../../../../assets/callerPhoto.svg';
import { useCallInfoStore } from "../../../../stores/useCallInfo";

import styles from './CallActions.module.scss';

export const CallActions: FC = () => {
  const callerPhoneNumber = useCallInfoStore((state) => state.call_data.from);

  return (
    <div className={styles.bottomInfoWrapper}>
      <div className={styles.phoneCallWrapper}>
        <img src={callerPhoto} alt="logo-small" />
        <span className={styles.callerPhone}>{callerPhoneNumber}</span>
      </div>

      <div className={styles.actionsWrapper}>
        <div className={styles.bottomInfo}>
          <img src={microMute} alt="logo-small" />
          <span className={styles.bottomInfoText}>mute</span>
        </div>

        <div className={styles.bottomInfo}>
          <img src={endCall} alt="logo-small" />
          <span className={styles.bottomInfoText}>end call</span>
        </div>
      </div>
    </div>
  );
};
