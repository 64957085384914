import React, { FC } from "react";
import cn from "classnames";
import errorNew from "../../../../assets/errorNew.svg";
// import warning from "../../../../assets/warning.svg";

// import waringNew from "../../../../assets/warningNew.svg";

import styles from "./callIdentify.module.scss";
import { statusesMap } from "../../../../utils/helpers/statuses";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { emitCommand, EmitCommandParams } from "../../../../utils/helpers/emitCommand";
import { TaskType } from "../../../../utils/enum/taskType";
import { useCallInfoStore } from "../../../../stores/useCallInfo";
import { ActionStatuses } from "../../../../utils/enum/actionStatuses";
import { useVadInfoStore } from "../../../../stores/useVadllInfo";
import { useConnectionStore } from "../../../../stores/useConnection";
import { Socket } from "socket.io-client";

type ButtonProps = {
  result: ActionStatuses;
};

const CallIdentify: FC<ButtonProps> = ({ result }) => {
  const navigate = useNavigate();

  const { text, icon, isSuccess, btnText, isEnroll, type, color, subText } = statusesMap?.get(result) || {};

  const socket = useConnectionStore((state) => state.socket as Socket);

  const callId = useCallInfoStore((state) => state.call_id);
  const clientId = useCallInfoStore((state) => state.call_data.client.client_id);
  const refreshVadTotalSeconds = useVadInfoStore((state) => state.refreshVadTotalSeconds);

  const handleEnrollResult = () => {
    if (isSuccess) {
      localStorage.removeItem("action");
      navigate("/");
      return;
    }

    const params: EmitCommandParams = {
      socket,
      task_type: TaskType.ENROLL,
      call_id: callId,
      client_id: clientId,
    };
    localStorage.setItem("action", "restart");
    refreshVadTotalSeconds();
    emitCommand(params);
  };

  return (
    <div className={cn(styles.resultWrapper)}>
      {result === ActionStatuses.ERROR ? (
          <>
            <img src={errorNew} alt="error"/>
            <div className={cn(styles.statusText)} style={{ color: color }}>{text}</div>
            <div className={styles.errorTitle} style={{ color: color }}>{subText}</div>
          </>
        ) :
        <>
          <img
            className={cn(styles.statusIcon)}
            src={icon}
            alt=""
          />
          <div
            className={cn(styles.statusText, isEnroll && styles.isEnrollText)}
            style={
              {
                color,
                marginTop: result !== ActionStatuses.VERIFIED && result !== ActionStatuses.ENROLL_PASSED ? "-26px" : "4px"
              }}
          >
            {text}
          </div>
          {result === ActionStatuses.FRAUD_DETECTED && (
            <div className={styles.speechTitle} style={{ color }}>Synthetic voice Alert</div>
          )}
          {type !== "hide" && (
            <div className={styles.homeScreenBtn}>
              <Button titleBtn={btnText as string} onClick={handleEnrollResult}/>
            </div>
          )}
        </>
      }
    </div>
  );
};

export default CallIdentify;
